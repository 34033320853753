import dayjs from "dayjs"

export const urlCleaner = pathName => {
  let result = pathName

  if (result[result.length - 1] == "/") {
    result = result.substring(0, result.length - 1)
  }

  return result
}

export const getArticleDateFormat = stringDate => {
  return dayjs(stringDate).format("DD MMMM YYYY | hh:mm WIB")
}

export const getDefaultArticleCategory = ({ category }) => {
  const tempCategory = category.toLowerCase()
  if (
    tempCategory.includes("perusahaan") ||
    tempCategory.includes("industri")
  ) {
    if (tempCategory == "perusahaan") {
      return "company"
    } else {
      return "industry"
    }
  } else {
    return tempCategory
  }
}
