import React, { useEffect } from "react"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import { urlCleaner } from "../../../helpers/stringHelper"

const BlogPreview = () => {
  const location = useLocation()
  useEffect(() => {
    navigate(
      urlCleaner(location.pathname).substring(
        0,
        urlCleaner(location.pathname).lastIndexOf("/")
      )
    )
  }, [])
  return <></>
}

export default BlogPreview
